/**
 * Wishlist link
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { saveCookie, isStoredInCookie } from "./utils/cookie"
import { Link } from "@StarberryUtils"
import $ from "jquery";

export const SaveItem = props => {
  const { itemId, itemClass } = props

  const [wishlist, setWishlist] = useState(0)

  const checkSave = pid => {
    let data = isStoredInCookie("favourites", pid)
    if (data) {
      $('html').addClass('has-wishlist');
    }
    return data
  }

  const handleSave = (event, pid) => {
    event.preventDefault()
    event.stopPropagation()

    let response = saveCookie("favourites", pid)
    if (response.count) {
      setWishlist(response.count)
      $('html').addClass('has-wishlist');
    } else {
      setWishlist(0)
      $('html').removeClass('has-wishlist');
    }
    $('.wishlist-count').text(response.count);
  }

  return (
    <Link
      className={checkSave(itemId) ? `${itemClass} saved` : itemClass}
      to="#"
      onClick={event => handleSave(event, itemId)}
    >
      <i className="icon-like">
                            </i>
                            {checkSave(itemId) ?
                            <span className="sm-text d-none d-md-block">
                                shortlisted
                         </span>
                         : <span className="sm-text d-none d-md-block">
                         shortlist
                  </span>}
    </Link>
  )
}

SaveItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  itemClass: PropTypes.string.isRequired,
}

SaveItem.defaultProps = {
  itemId: ``,
  itemClass: ``,
}
